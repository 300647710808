/* eslint-disable react/no-unstable-nested-components */
import React, { useRef } from 'react'
import useScript from 'hooks/useScript'

const JSObject = ({ data }) => {
  const { src, type } = data.json
  const scriptRef = useRef(null)
  const status = useScript(src, scriptRef)

  if (type === 'acquia') {
    return (
      <div ref={scriptRef} id="mauticform" className={type}>
        <h3>Let's Connect</h3>
        <p>Please enter your email address below so we can keep in touch with important information about ORU.</p>
        {status === 'loading' && 'Loading...'}
        {status === 'error' && 'Error'}
      </div>
    )
  }
}

export default JSObject
