import React from 'react'
import Card from '../Card'

function ContentfulComponentCard({ data }) {
  const { image, description, text, cardLinkUrl } = data
  const { childMarkdownRemark: descriptionText } = description
  const { childMarkdownRemark: title } = text
  return <Card title={title.html} description={descriptionText.html} image={image.image} cardLinkUrl={cardLinkUrl} />
}

export default ContentfulComponentCard
